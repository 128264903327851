'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _coerceSelection = require('./coerceSelection');

var _coerceSelection2 = _interopRequireDefault(_coerceSelection);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EMPTY = {};


var memoize = function memoize(substyle) {
  return function (select, defaultStyle) {
    var cacheKey = defaultStyle || EMPTY;
    substyle.memoize = substyle.memoize || new WeakMap();
    var mapEntry = void 0;
    if (!substyle.memoize.has(cacheKey)) {
      mapEntry = {};
      substyle.memoize.set(cacheKey, mapEntry);
    } else {
      mapEntry = substyle.memoize.get(cacheKey);
    }
    var selectHash = (0, _coerceSelection2.default)(select).join(' ');
    return selectHash in mapEntry ? mapEntry[selectHash] : mapEntry[selectHash] = substyle(select || [], defaultStyle);
  };
};

exports.default = memoize;
module.exports = exports['default'];