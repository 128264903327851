"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var keys = exports.keys = function keys(obj) {
  return obj === Object(obj) ? Object.keys(obj) : [];
};

var values = exports.values = function values(obj) {
  return obj === Object(obj) ? Object.values(obj) : [];
};

function mergeDeep(target, source) {
  var output = assign({}, target);
  if (isPlainObject(target) && isPlainObject(source)) {
    keys(source).forEach(function (key) {
      if (isPlainObject(source[key])) {
        if (!(key in target)) assign(output, _defineProperty({}, key, source[key]));else output[key] = mergeDeep(target[key], source[key]);
      } else {
        assign(output, _defineProperty({}, key, source[key]));
      }
    });
  }
  return output;
}

var merge = exports.merge = function merge(target) {
  for (var _len = arguments.length, sources = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    sources[_key - 1] = arguments[_key];
  }

  return sources.reduce(function (t, s) {
    return mergeDeep(t, s);
  }, target);
};

var assign = exports.assign = function assign(target) {
  for (var _len2 = arguments.length, sources = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    sources[_key2 - 1] = arguments[_key2];
  }

  return Object.assign.apply(Object, [target].concat(sources));
};

var identity = exports.identity = function identity(value) {
  return value;
};

var omit = function omit(obj, keys) {
  var _obj = _extends({}, obj),
      other = _objectWithoutProperties(_obj, []);

  if (keys) {
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = keys[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var key = _step.value;

        delete other[key];
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
  }
  return other;
};

exports.omit = omit;
var isPlainObject = exports.isPlainObject = function isPlainObject(obj) {
  return obj === Object(obj) && !(obj instanceof Date) && !Array.isArray(obj);
};

var compact = exports.compact = function compact(arr) {
  return (arr || []).filter(Boolean);
};