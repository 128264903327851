'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContextTypes = exports.PropTypes = exports.PROPS_DECORATOR_CONTEXT_NAME = exports.ENHANCER_CONTEXT_NAME = undefined;

var _ContextTypes;

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var ENHANCER_CONTEXT_NAME = exports.ENHANCER_CONTEXT_NAME = '__substyle__enhancer';
var PROPS_DECORATOR_CONTEXT_NAME = exports.PROPS_DECORATOR_CONTEXT_NAME = '__substyle__propsDecorator';

var StylePT = _propTypes2.default.oneOfType([_propTypes2.default.func, _propTypes2.default.object]);

var ClassNamesPT = _propTypes2.default.objectOf(_propTypes2.default.string);

var PropTypes = exports.PropTypes = {
  style: StylePT,
  className: _propTypes2.default.string,
  classNames: ClassNamesPT,
  innerRef: _propTypes2.default.oneOfType([_propTypes2.default.func, _propTypes2.default.shape({
    current: typeof Element === 'undefined' ? _propTypes2.default.any : _propTypes2.default.instanceOf(Element)
  })])
};

var ContextTypes = exports.ContextTypes = (_ContextTypes = {}, _defineProperty(_ContextTypes, ENHANCER_CONTEXT_NAME, _propTypes2.default.func), _defineProperty(_ContextTypes, PROPS_DECORATOR_CONTEXT_NAME, _propTypes2.default.func), _ContextTypes);