var E=Object.defineProperty;var f=Object.getOwnPropertySymbols;var G=Object.prototype.hasOwnProperty,$=Object.prototype.propertyIsEnumerable;var d=(o,t,e)=>t in o?E(o,t,{enumerable:!0,configurable:!0,writable:!0,value:e}):o[t]=e,c=(o,t)=>{for(var e in t||(t={}))G.call(t,e)&&d(o,e,t[e]);if(f)for(var e of f(t))$.call(t,e)&&d(o,e,t[e]);return o};import*as r from"react";import*as n from"prop-types";import{useUID as K}from"@twilio-paste/uid-library";import{IconWrapper as z}from"@twilio-paste/icons/esm/helpers/IconWrapper";import{useTheme as B}from"@twilio-paste/theme";import{isIconSizeTokenProp as D}from"@twilio-paste/style-props";import{styled as a}from"@twilio-paste/styling-library";import{keyframes as h}from"@twilio-paste/styling-library";var y=45,p={cx:50,cy:50,r:y},i=Math.PI*2*y;var S=h`
  0%,
  15% {
    stroke-dashoffset: ${i*.9999};
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: ${i*.2};
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: ${i*.9999};
    transform: rotate(360deg);
  }
`,u=h`
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
`;var g=a.circle({transformOrigin:"center",opacity:.25}),x=a.circle(({show:o})=>({transformOrigin:"center",animation:`1.5s ease-in-out infinite both ${u}`,strokeDasharray:i,opacity:o?1:0})),P=a.svg({height:"100%",width:"100%",display:"block",animation:`4.25s linear infinite both ${S}`});var w=r.forwardRef(({size:o,color:t="currentColor",title:e,as:C,display:k,decorative:m,delay:s=250,element:T="SPINNER"},b)=>{let l=`spinner-${K()}`,{borderWidths:{borderWidth40:v}}=B(),[I,R]=r.useState(s===0);if(!m&&e==null)throw new Error("[Spinner]: Missing a title for non-decorative icon.");return r.useEffect(()=>{if(s===0)return;let W=setTimeout(()=>R(!0),s);return()=>clearTimeout(W)},[s]),r.createElement(z,{as:C,element:T,display:k,size:o,color:t,"aria-hidden":m,ref:b},r.createElement(P,{viewBox:"0 0 100 100",xmlns:"http://www.w3.org/2000/svg","aria-labelledby":l},e?r.createElement("title",{id:l},e):null,r.createElement("g",{strokeWidth:v,stroke:"currentColor",strokeLinecap:"round",fill:"transparent"},r.createElement(g,c({},p)),r.createElement(x,c({show:I},p)))))});w.displayName="Spinner";w.propTypes={title:n.string,delay:n.number,element:n.string,size:D};export{w as Spinner};
