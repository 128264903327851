var E=Object.defineProperty,v=Object.defineProperties;var T=Object.getOwnPropertyDescriptors;var n=Object.getOwnPropertySymbols;var s=Object.prototype.hasOwnProperty,d=Object.prototype.propertyIsEnumerable;var m=(e,o,r)=>o in e?E(e,o,{enumerable:!0,configurable:!0,writable:!0,value:r}):e[o]=r,l=(e,o)=>{for(var r in o||(o={}))s.call(o,r)&&m(e,r,o[r]);if(n)for(var r of n(o))d.call(o,r)&&m(e,r,o[r]);return e},p=(e,o)=>v(e,T(o));var u=(e,o)=>{var r={};for(var t in e)s.call(e,t)&&o.indexOf(t)<0&&(r[t]=e[t]);if(e!=null&&n)for(var t of n(e))o.indexOf(t)<0&&d.call(e,t)&&(r[t]=e[t]);return r};import*as a from"react";import{styled as z,themeGet as f}from"@twilio-paste/styling-library";import{Box as A,safelySpreadBoxProps as C}from"@twilio-paste/box";import{keyframes as H}from"@twilio-paste/styling-library";var c=H`
  0% {
    transform: translateX(-100%) skew(155deg);
  }
  50%,
  100% {
    transform: translateX(100%) skew(155deg);
  }
`;var D=z.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    90deg,
    transparent,
    ${f("backgroundColors.colorBackground")} 40%,
    ${f("backgroundColors.colorBackground")} 60%,
    transparent
  );
  transform: translateX(-100%) skew(155deg);
  animation-timing-function: cubic-bezier(0.06, 0.42, 0.57, 0.89);
  animation-name: ${c};
  animation-duration: 4.5s;
  animation-iteration-count: infinite;
`,K=a.forwardRef((M,w)=>{var i=M,{borderBottomLeftRadius:e,borderBottomRightRadius:o,borderRadius:r="borderRadius20",borderTopLeftRadius:t,borderTopRightRadius:g,element:k="SKELETON_LOADER",display:b,height:L="sizeIcon20",maxHeight:R,maxWidth:y,minHeight:h,minWidth:x,size:B=null,width:S=null}=i,P=u(i,["borderBottomLeftRadius","borderBottomRightRadius","borderRadius","borderTopLeftRadius","borderTopRightRadius","element","display","height","maxHeight","maxWidth","minHeight","minWidth","size","width"]);return a.createElement(A,p(l({},C(P)),{"aria-busy":"true",backgroundColor:"colorBackgroundStrong",borderBottomLeftRadius:e,borderBottomRightRadius:o,borderRadius:r,borderTopLeftRadius:t,borderTopRightRadius:g,display:b,element:k,height:L,maxHeight:R,maxWidth:y,minHeight:h,minWidth:x,overflow:"hidden",pointerEvents:"none",position:"relative",userSelect:"none",size:B,width:S,ref:w}),a.createElement(D,null))});K.displayName="SkeletonLoader";export{K as SkeletonLoader};
