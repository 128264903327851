'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _pickStyles = require('./pickStyles');

// many css-in-js libs process keyframes objects as the value for `animationName`
var defaultObjectPropsWhitelist = ['animationName'];

var defaultPropsDecorator = function defaultPropsDecorator(_ref) {
  var style = _ref.style,
      className = _ref.className;
  return _extends({}, style ? { style: (0, _pickStyles.pickDirectStyles)(style, defaultObjectPropsWhitelist) } : {}, className ? { className: className } : {});
};

exports.default = defaultPropsDecorator;
module.exports = exports['default'];