import { Match } from 'autolinker/dist/es2015';
export class CustomMatch extends Match {
    constructor({ matcher, replacerArgs, ...config }) {
        super(config);
        this.matcher = matcher;
        this.replacerArgs = replacerArgs;
    }
    getType() {
        return this.matcher.type || 'custom';
    }
    getAnchorHref() {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.matcher).getLinkUrl) === null || _b === void 0 ? void 0 : _b.call(_a, this.replacerArgs)) !== null && _c !== void 0 ? _c : this.matchedText;
    }
    getAnchorText() {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.matcher).getLinkText) === null || _b === void 0 ? void 0 : _b.call(_a, this.replacerArgs)) !== null && _c !== void 0 ? _c : this.matchedText;
    }
    getMatcher() {
        return this.matcher;
    }
    getReplacerArgs() {
        return this.replacerArgs;
    }
}
