'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var isModifier = exports.isModifier = function isModifier(key) {
  return key[0] === '&';
};
var isElement = exports.isElement = function isElement(key) {
  return !isModifier(key);
};