import*as e from"react";import*as r from"prop-types";import{Box as a}from"@twilio-paste/box";import{styled as n}from"@twilio-paste/styling-library";var p=/^(\d+:\d*)$/,c=t=>p.test(t),d=({ratio:t})=>{if(!(t!=null&&t!==""))throw new Error("[Paste: AspectRatio] Missing 'ratio' prop.");if(!c(t))throw new Error("[Paste: AspectRatio] 'ratio' is invalid. Use a colon separated number pattern (4:3).")},l=n.div`
  position: relative;

  embed,
  iframe,
  object,
  video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`,R=e.forwardRef((t,o)=>{d(t);let i=t.ratio.split(":").map(Number),s=i[1]/i[0]*100;return e.createElement(l,{ref:o,style:{paddingBottom:`${s}%`}},e.createElement(a,{position:"absolute",top:0,right:0,bottom:0,left:0},t.children))});R.displayName="AspectRatio";export{R as AspectRatio};
