'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _invariant = require('invariant');

var _invariant2 = _interopRequireDefault(_invariant);

var _utils = require('./utils');

var _defaultPropsDecorator = require('./defaultPropsDecorator');

var _defaultPropsDecorator2 = _interopRequireDefault(_defaultPropsDecorator);

var _pickStyles = require('./pickStyles');

var _filterKeys = require('./filterKeys');

var _coerceSelection = require('./coerceSelection');

var _coerceSelection2 = _interopRequireDefault(_coerceSelection);

var _memoize = require('./memoize');

var _memoize2 = _interopRequireDefault(_memoize);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var guessBaseClassName = function guessBaseClassName(classNames) {
  // all class names must start with the same prefix: the component's base class name
  // which will finally go to the container element
  var firstKey = classNames && (0, _utils.keys)(classNames)[0];
  return firstKey && firstKey.split('__')[0].split('--')[0];
};

var deriveClassNames = function deriveClassNames(className, elementKeys, modifierKeys) {
  // do not derive class names, if the user did not specify any class name
  if (!className) {
    return undefined;
  }

  // derive class names based using the passed modifier/element keys
  var firstClassName = className.split(' ')[0];
  var derivedClassNames = [].concat(_toConsumableArray(elementKeys.length === 0 ? modifierKeys.map(function (key) {
    return firstClassName + '--' + key.substring(1);
  }) : []), _toConsumableArray(elementKeys.map(function (key) {
    return firstClassName + '__' + key;
  })));

  // also use the provided `className` if there is no sub-element selection
  return elementKeys.length === 0 ? [className].concat(_toConsumableArray(derivedClassNames)) : derivedClassNames;
};

function createSubstyle(_ref) {
  var style = _ref.style,
      className = _ref.className,
      classNames = _ref.classNames;
  var propsDecorator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _defaultPropsDecorator2.default;

  var baseClassName = className || guessBaseClassName(classNames);

  var substyle = typeof style === 'function' ? style : (0, _memoize2.default)(function (select, defaultStyle) {
    var selectedKeys = (0, _coerceSelection2.default)(select);

    (0, _invariant2.default)(Array.isArray(selectedKeys), 'First parameter must be a string, an array of strings, ' + 'a plain object with boolean values, or a falsy value.');

    (0, _invariant2.default)(!defaultStyle || (0, _utils.isPlainObject)(defaultStyle), 'Optional second parameter must be a plain object.');

    var modifierKeys = selectedKeys.filter(_filterKeys.isModifier);
    var elementKeys = selectedKeys.filter(_filterKeys.isElement);

    var collectElementStyles = elementKeys.length > 0 ? function (fromStyle) {
      return (0, _utils.values)((0, _pickStyles.pickNestedStyles)(fromStyle, elementKeys));
    } : function (fromStyle) {
      return [fromStyle];
    };

    var collectSelectedStyles = function collectSelectedStyles() {
      var fromStyle = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      return collectElementStyles((0, _pickStyles.hoistModifierStylesRecursive)(fromStyle, modifierKeys));
    };

    var derivedClassNames = deriveClassNames(baseClassName, elementKeys, modifierKeys);

    return createSubstyle(_extends({}, (style || defaultStyle) && {
      style: _utils.merge.apply(undefined, [{}].concat(_toConsumableArray(collectSelectedStyles(defaultStyle)), _toConsumableArray(collectSelectedStyles(style))))
    }, derivedClassNames && {
      className: derivedClassNames.join(' ')
    }, classNames && { classNames: classNames }), propsDecorator);
  });

  // $FlowFixMe Flow does not believe that also a function can be spread
  var styleProps = _extends({}, typeof style === 'function' ? style : { style: style });

  var classNameSplit = [].concat(_toConsumableArray(styleProps.className ? styleProps.className.split(' ') : []), _toConsumableArray(baseClassName ? baseClassName.split(' ') : []));
  var mappedClassNames = classNames ? (0, _utils.compact)(classNameSplit.map(function (singleClassName) {
    return classNames[singleClassName];
  })) : classNameSplit;

  var propsForSpread = propsDecorator(_extends({}, styleProps, mappedClassNames.length > 0 ? {
    className: mappedClassNames.join(' ')
  } : {}));

  // assign `style` and/or `className` props to the return function object
  (0, _utils.assign)(substyle, propsForSpread);
  return substyle;
}

exports.default = createSubstyle;
module.exports = exports['default'];