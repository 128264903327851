'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _hoistNonReactStatics = require('hoist-non-react-statics');

var _hoistNonReactStatics2 = _interopRequireDefault(_hoistNonReactStatics);

var _warning = require('warning');

var _warning2 = _interopRequireDefault(_warning);

var _utils = require('./utils');

var _createSubstyle = require('./createSubstyle');

var _createSubstyle2 = _interopRequireDefault(_createSubstyle);

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var isStatelessFunction = function isStatelessFunction(Component
// $FlowFixMe
) {
  return Component.prototype && !Component.prototype.render;
};

var createDefaultStyle = function createDefaultStyle(defaultStyle, getModifiers) {
  var shouldUpdate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function () {
    return true;
  };
  return function (WrappedComponent) {
    var WithDefaultStyle = function (_Component) {
      _inherits(WithDefaultStyle, _Component);

      function WithDefaultStyle(props, context) {
        _classCallCheck(this, WithDefaultStyle);

        var _this = _possibleConstructorReturn(this, (WithDefaultStyle.__proto__ || Object.getPrototypeOf(WithDefaultStyle)).call(this, props, context));

        _this.setWrappedInstance = function (ref) {
          _this.wrappedInstance = ref;
          var innerRef = _this.props.innerRef;

          if (typeof innerRef === 'function') {
            innerRef(ref);
          } else if (innerRef && typeof innerRef !== 'string') {
            innerRef.current = ref;
          }
        };

        var style = props.style,
            className = props.className,
            classNames = props.classNames,
            _ = props.innerRef,
            rest = _objectWithoutProperties(props, ['style', 'className', 'classNames', 'innerRef']);

        _this.substyle = (0, _createSubstyle2.default)({ style: style, className: className, classNames: classNames }, _this.context[_types.PROPS_DECORATOR_CONTEXT_NAME]);

        if (typeof defaultStyle === 'function') {
          _this.defaultStyle = defaultStyle(rest);
        }
        return _this;
      }

      _createClass(WithDefaultStyle, [{
        key: 'shouldComponentUpdate',
        value: function shouldComponentUpdate(_ref) {
          var style = _ref.style,
              className = _ref.className,
              classNames = _ref.classNames,
              rest = _objectWithoutProperties(_ref, ['style', 'className', 'classNames']);

          var _props = this.props,
              prevStyle = _props.style,
              prevClassName = _props.className,
              prevClassNames = _props.classNames,
              _ = _props.innerRef,
              prevRest = _objectWithoutProperties(_props, ['style', 'className', 'classNames', 'innerRef']);

          if (style !== prevStyle || className !== prevClassName || classNames !== prevClassNames) {
            this.substyle = (0, _createSubstyle2.default)({ style: style, className: className, classNames: classNames }, this.context[_types.PROPS_DECORATOR_CONTEXT_NAME]);
          }

          if (typeof defaultStyle === 'function') {
            if (shouldUpdate(rest, prevRest)) {
              this.defaultStyle = defaultStyle(rest);
            }
          }

          return true;
        }
      }, {
        key: 'render',
        value: function render() {
          var _props2 = this.props,
              innerRef = _props2.innerRef,
              _0 = _props2.style,
              _1 = _props2.className,
              _2 = _props2.classNames,
              rest = _objectWithoutProperties(_props2, ['innerRef', 'style', 'className', 'classNames']);

          var EnhancedWrappedComponent = this.getWrappedComponent();
          var modifiers = getModifiers ? getModifiers(rest) : [];
          return (0, _react.createElement)(EnhancedWrappedComponent, _extends({
            style: this.substyle(modifiers, this.defaultStyle || defaultStyle),
            ref: isStatelessFunction(EnhancedWrappedComponent) ? undefined : // $FlowFixMe
            this.setWrappedInstance
          }, rest));
        }
      }, {
        key: 'getWrappedComponent',
        value: function getWrappedComponent() {
          var _context$ENHANCER_CON = this.context[_types.ENHANCER_CONTEXT_NAME],
              enhance = _context$ENHANCER_CON === undefined ? _utils.identity : _context$ENHANCER_CON;


          if (this.memoizedEnhance !== enhance) {
            this.memoizedEnhance = enhance;
            this.enhancedWrappedComponent = enhance(WrappedComponent);
            if (this.enhancedWrappedComponent.propTypes) {
              this.enhancedWrappedComponent.propTypes = _extends({}, this.enhancedWrappedComponent.propTypes, {
                style: _types.PropTypes.style
              });
            }
          }

          return this.enhancedWrappedComponent || WrappedComponent;
        }
      }, {
        key: 'getWrappedInstance',
        value: function getWrappedInstance() {
          (0, _warning2.default)(true, '`getWrappedInstance()` is deprecated and will be removed with the next major release. ' + 'Instead, use the `innerRef` prop to get a ref to the wrapped instance.');
          return this.wrappedInstance;
        }
      }]);

      return WithDefaultStyle;
    }(_react.Component);

    var wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name;
    WithDefaultStyle.displayName = 'withDefaultStyle(' + wrappedComponentName + ')';

    // define prop types based on WrappedComponent's prop types
    WithDefaultStyle.propTypes = _extends({}, WrappedComponent.propTypes, _types.PropTypes);

    WithDefaultStyle.contextTypes = _types.ContextTypes;

    // expose WrappedComponent, e.g., for testing purposes
    WithDefaultStyle.WrappedComponent = WrappedComponent;

    return (0, _hoistNonReactStatics2.default)(WithDefaultStyle, WrappedComponent);
  };
};

exports.default = createDefaultStyle;
module.exports = exports['default'];